import { useState, useEffect, useRef } from 'react';

const useIntersectionObserver = fetchDataCallback => {
  const [hasFetched, setHasFetched] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    let observer;
    if (containerRef.current && !hasFetched) {
      observer = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          setHasFetched(true); // Marca como 'fetched' para evitar chamadas repetidas
          fetchDataCallback(); // Executa a função de busca
          observer.disconnect(); // Desconecta o observer após a primeira vez que o componente se torna visível
        }
      });

      observer.observe(containerRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [hasFetched, fetchDataCallback]);

  return { containerRef };
};

export default useIntersectionObserver;
